import {
  ProviderActionType,
  Relative,
  RelativeActionType,
  Sorting,
} from '@kaa/api/providers';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { ActionsHandler } from '@kaa/common/handlers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  Icon,
  selectPage,
  SwAlert,
  SwBadge,
  SwButton,
  SwColumn,
  SwContainer,
  SwDataTable,
  SwFetchErrorMessage,
  SwFormGroup,
  SwGrid,
  SwLink,
  SwModal,
  SwMultiselect,
  SwPaginator,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLabelForSorting, TableHead } from '../../../components';
import { Modals } from '../../../constants';
import { dataTest } from '../../../datatest/keys';
import { getPath, Routes } from '../../../routes';
import { useApi, useSelectedProviderState } from '../../../utils';
import { openModalWithPageView } from '../../../utils/google-analytics';
import { RelativeAddModal } from './components/relativeAddModal/RelativeAddModal';
import { RELATIVES_PAGE_SIZE } from './RelativesScreen.constants';
import './RelativesScreen.style.scss';

const sortRelativesByPostcodes = (
  subsidiaries: Relative[],
  ascending: boolean,
) => {
  return [...subsidiaries].sort((a, b) =>
    ascending
      ? a.postcode.localeCompare(b.postcode, 'kn', {
          numeric: true,
        })
      : b.postcode.localeCompare(a.postcode, 'kn', {
          numeric: true,
        }),
  );
};

export const RelativesScreen: React.FC<RouteComponentProps> = () => {
  const [subsidiaries, setSubsidiaries] = useState<Relative[]>([]);

  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedPostcodes, setSelectedPostcodes] = useState<string[]>([]);
  const [selectedSorting, setSelectedSorting] = useState<Sorting>(
    Sorting.POSTCODE_ASC,
  );

  const [newRelativeStatus, setNewRelativeStatus] = useState<{
    type: AlertType;
    msg: string;
  } | null>(null);

  const provider = useSelectedProviderState();
  const { providers } = useApi();
  const dispatchAction = useActionDispatch();
  const { t } = useTranslation();

  const [
    { value: fetchedRelatives, loading, error },
    getRelatives,
  ] = useAsyncCallback(
    async () => {
      const response = await providers.getProviderRelatives(provider.id, {
        sort: Sorting.POSTCODE_ASC,
      });

      const {
        data: { data, actions },
      } = response;

      if (actions) {
        dispatchAction({ type: ActionType.ADD, payload: actions });
      }

      return data;
    },
    [provider.id, dispatchAction, providers],
    { loading: true },
  );

  useEffect(() => {
    getRelatives();
  }, [getRelatives]);

  useEffect(() => {
    if (fetchedRelatives) {
      setSubsidiaries(fetchedRelatives.subsidiaries);
    }
  }, [fetchedRelatives]);

  const filteredSubsidiaries = useMemo(() => {
    const filteredPostcodes = selectedPostcodes.length
      ? subsidiaries.filter(({ postcode }) =>
          selectedPostcodes.includes(postcode),
        )
      : subsidiaries;

    if (selectedSorting) {
      return sortRelativesByPostcodes(
        filteredPostcodes,
        selectedSorting === Sorting.POSTCODE_ASC,
      );
    }

    return filteredPostcodes;
  }, [subsidiaries, selectedSorting, selectedPostcodes]);

  const postalCodesOptions = useMemo(() => {
    const uniqueSortedPostcodes = [
      ...Array.from(new Set(subsidiaries.map(({ postcode }) => postcode))),
    ].sort();

    return uniqueSortedPostcodes.map((postcode) => ({
      value: postcode,
      label: postcode,
    }));
  }, [subsidiaries]);

  if (loading) {
    return <SwContainer loading />;
  }

  if (error || !fetchedRelatives) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getRelatives} />
      </SwContainer>
    );
  }

  const addNewRelative = ({
    data,
    actions,
  }: {
    data: Relative;
    actions?: { [key: string]: { [key: string]: boolean } };
  }) => {
    toggleModalById(Modals.ADD_RELATIVE_MODAL_ID);
    setSubsidiaries((currentSubsidiaries) => [data, ...currentSubsidiaries]);

    if (actions) {
      dispatchAction({ type: ActionType.ADD, payload: actions });
    }

    setNewRelativeStatus({
      type: AlertType.SUCCESS,
      msg: t(i18nKeys.relatives.myAffiliates.alert.successMessage),
    });
  };

  const { headquarter } = fetchedRelatives;

  const head = [
    { text: t(i18nKeys.general.label.name) },
    { text: t(i18nKeys.general.label.authorizationNumber) },
    {
      text: t(i18nKeys.general.label.postcode),
      label: getLabelForSorting(Sorting.POSTCODE_ASC, selectedSorting, t),
      onClick: () => {
        setSelectedSorting((prefix: Sorting) =>
          prefix === Sorting.POSTCODE_ASC
            ? Sorting.POSTCODE_DESC
            : Sorting.POSTCODE_ASC,
        );
      },
    },
    { text: '' },
  ];

  return (
    <SwContainer>
      <SwGrid className="vl-u-spacer--medium" modStacked>
        <SwColumn width="10" widthS="12">
          <SwTitle tagName="h2">
            {t(i18nKeys.relatives.myHeadquarter.title)}
          </SwTitle>
          <SwGrid>
            <SwColumn width="4" widthS="8">
              <SwTitle tagName="h5" modSans>
                {headquarter.name}
              </SwTitle>
            </SwColumn>
            <SwColumn width="8" widthS="4">
              <SwLink
                data-testid={dataTest.relatives.editHeadQuarter}
                icon={Icon.EDIT}
                modIconAfter
                to={getPath(Routes.ENTERPRISE_DETAILS, {
                  params: {
                    enterpriseId: headquarter.id,
                  },
                })}
              >
                {t(i18nKeys.general.cta.detail)}
              </SwLink>
            </SwColumn>
            <SwColumn
              width="12"
              data-testid={dataTest.relatives.headquarterCompanyId}
            >
              <p>{headquarter.id}</p>
            </SwColumn>
            <SwColumn width="12">
              <p>{headquarter.postcode}</p>
            </SwColumn>
          </SwGrid>
        </SwColumn>
      </SwGrid>
      {subsidiaries.length === 0 && (
        <SwGrid className="vl-u-spacer--medium" modStacked>
          <SwColumn width="10" widthS="12">
            <SwTitle tagName="h2">
              {t(i18nKeys.relatives.myAffiliates.title, {
                count: subsidiaries.length,
              })}
            </SwTitle>

            <div className="relativesFormGroup">
              <ActionsHandler
                resourceId={provider.resourceId}
                actions={[ProviderActionType.CAN_CREATE_RELATIVE]}
              >
                <SwButton
                  modLarge
                  onClick={() => {
                    openModalWithPageView(Modals.ADD_RELATIVE_MODAL_ID, {
                      appendToPath: 'step1',
                    });
                    setNewRelativeStatus(null);
                  }}
                  data-testid={dataTest.relatives.addNewAffiliate}
                >
                  {t(i18nKeys.relatives.myAffiliates.addNewAffiliate)}
                </SwButton>
              </ActionsHandler>
            </div>
          </SwColumn>
        </SwGrid>
      )}
      {subsidiaries.length >= 1 && (
        <>
          <SwGrid modStacked>
            <SwColumn width="10" widthS="12">
              <SwTitle tagName="h2">
                {t(i18nKeys.relatives.myAffiliates.title, {
                  count: subsidiaries.length,
                })}
              </SwTitle>

              <div className="relativesFormGroup">
                <ActionsHandler
                  resourceId={provider.resourceId}
                  actions={[ProviderActionType.CAN_CREATE_RELATIVE]}
                >
                  <SwButton
                    modLarge
                    onClick={() => {
                      openModalWithPageView(Modals.ADD_RELATIVE_MODAL_ID, {
                        appendToPath: 'step1',
                      });
                      setNewRelativeStatus(null);
                    }}
                    data-testid={dataTest.relatives.addNewAffiliate}
                  >
                    {t(i18nKeys.relatives.myAffiliates.addNewAffiliate)}
                  </SwButton>
                </ActionsHandler>

                <SwFormGroup>
                  <SwMultiselect
                    placeHolder={t(i18nKeys.relatives.searchForAffiliate)}
                    options={postalCodesOptions}
                    onChange={(options) => {
                      if (options && Array.isArray(options) && options.length) {
                        setSelectedPostcodes(options.map(({ value }) => value));
                      } else {
                        setSelectedPostcodes([]);
                      }
                    }}
                  />
                </SwFormGroup>
              </div>
            </SwColumn>
            {newRelativeStatus && (
              <SwColumn width="10" widthS="12">
                <SwAlert
                  {...getAlertPropsByType(newRelativeStatus.type)}
                  close={() => setNewRelativeStatus(null)}
                  closable
                  modSmall
                >
                  {newRelativeStatus.msg}
                </SwAlert>
              </SwColumn>
            )}
            <SwColumn width="10" widthS="12">
              <SwGrid>
                <SwColumn>
                  <div className="vl-u-table-overflow">
                    <SwDataTable modCollapsedXs>
                      <TableHead
                        elements={head}
                        data-testid={dataTest.relatives.tableHeader}
                      />
                      <tbody data-testid={dataTest.relatives.tableBody}>
                        {selectPage<Relative>(
                          filteredSubsidiaries,
                          selectedPage,
                          RELATIVES_PAGE_SIZE,
                        ).map(
                          ({
                            id,
                            name,
                            postcode,
                            isActive,
                            resourceId,
                          }: Relative) => (
                            <tr
                              key={`table-row-${id}`}
                              className={!isActive ? 'disableRelative' : ''}
                            >
                              <td data-title={t(i18nKeys.general.label.name)}>
                                {!isActive && (
                                  <SwBadge
                                    modWarning
                                    modXsmall
                                    icon={Icon.LOCK}
                                  />
                                )}
                                {name}
                              </td>
                              <td
                                data-title={t(
                                  i18nKeys.general.label.authorizationNumber,
                                )}
                                data-testid={
                                  dataTest.relatives.affiliateCompanyId
                                }
                              >
                                {id}
                              </td>
                              <td
                                data-title={t(i18nKeys.general.label.postcode)}
                              >
                                {postcode}
                              </td>
                              <td>
                                <ActionsHandler
                                  resourceId={resourceId}
                                  actions={[RelativeActionType.CAN_SEE_DETAILS]}
                                >
                                  <SwLink
                                    data-testid={
                                      dataTest.relatives.editAffiliate
                                    }
                                    icon={Icon.ARROW_RIGHT}
                                    modIconAfter
                                    to={getPath(Routes.ENTERPRISE_DETAILS, {
                                      params: {
                                        enterpriseId: id,
                                      },
                                    })}
                                  >
                                    {t(i18nKeys.general.cta.detail)}
                                  </SwLink>
                                </ActionsHandler>
                              </td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </SwDataTable>
                  </div>
                </SwColumn>
              </SwGrid>
            </SwColumn>
            <SwColumn width="10" widthS="12">
              <SwPaginator
                itemCount={filteredSubsidiaries.length}
                pageSize={RELATIVES_PAGE_SIZE}
                selectedPage={selectedPage}
                setPage={setSelectedPage}
              />
            </SwColumn>
          </SwGrid>
        </>
      )}
      <SwModal
        id={Modals.ADD_RELATIVE_MODAL_ID}
        closable
        component={RelativeAddModal}
        bankAccount={provider.iban}
        vatNumber={provider.vatNumber}
        providerId={provider.id}
        onSubmit={addNewRelative}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
      />
    </SwContainer>
  );
};
